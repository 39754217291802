<template>

  <v-container id="statusRequestForTransporter" class="h-100">

    <div class="back-button text-right">
      <v-btn icon @click="goBack">
        <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <div class="pb-3 mb-3">
      <div class="top-text my-3">
        <h1 class="title is-1 thin black--text">Что</h1>
        <h1 class="title is-1 black--text">везём?</h1>
      </div>
    </div>

    <div>

      <p v-if="status === 'loading'" class="title is-3">Загрузка...</p>
      <p v-else-if="status === 'in_process'" class="title is-3" style="color: #4d2a09">Ваша заявка в обработке</p>
      <p v-else-if="status === 'transporter'" class="title is-3" style="color: #007539">Вы уже перевозчик</p>
      <p v-else-if="status === 'error'" class="title is-3" style="color: #fc4e2f">Ошибка получения информации</p>
      <p v-else-if="status === 'rejected'" class="title is-3" style="color: #fc4e2f">Ваша заявка не принята</p>

      <p v-if="status === 'transporter'" class="title is-4">Если ваш интерфейс не содержит
      функций перевозчика, перезагрузите страницу или очистите кэш браузера (Ctrl+F5)</p>

      <p class="title is-4">
        <br>По вопросам пишите на почту<br>
          <a href="mailto:uralservisegroup@yandex.ru">uralservisegroup@yandex.ru</a>
      </p>

      <p class="title is-4">
        <br>Наш телеграм<br>
          <a href="https://t.me/vezem_perm">
            <i class="fab fa-telegram-plane"></i> @vezem_perm
          </a>
      </p>

    </div>

  </v-container>

</template>

<script>
export default {
  name: 'StatusRequestForTransporter',
  data() {
    return {
      version: this.$version,
      buildType: process.env.VUE_APP_BUILD_TYPE,
      status: 'loading',
    };
  },
  created() {
    this.$store.dispatch('AUTH').then(() => {
      let request = this.$store.getters.PROFILE_EXTRA_INFO.active_carrier_check;
      if (request === true || request === 'true') {
        this.status = "in_process";
      } else {
        if (this.$store.getters.ACCOUNT_TYPE == 1) {
          this.status = "transporter";
        } else if (request === false || request === 'false') {
          this.status = "rejected";
        } else {
          this.status = "error";
        }
      }
    }).catch(() => {
      this.$buefy.toast.open({
        message: 'Ошибка получения информации',
        type: 'is-danger'
      })
      this.status = 'error';
    })
  },
  computed: {
    getBuildTypeFormat() {
      let subStr = this.buildType.substring(0, 1);
      if (subStr) {
        if (subStr === 'p') {
          return '';
        } else {
          return subStr;
        }
      } else {
        // We have a big problems
        return 'h';
      }
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  },
};
</script>

<style scoped>
.block-content img {
  max-width: 100%;
  height: auto;
}

.title.is-1 {
  font-size: 4.5rem !important;
}

.title.is-1.thin {
  font-weight: 300;
}

.title.is-4 {
  margin-bottom: 0px !important;
  line-height: normal !important;
}

.title.is-3 {
  font-size: 1.55rem!important;
}

.svg-inline--fa.fa-telegram-plane {
  padding-top: 5px;
}

</style>
